.marquee {
  height: 30px;
  width: 100%;
  margin-top: 100px;
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee .marquee-content {
  font-size: 18px;
  letter-spacing: 2px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 10s linear infinite;
}

/* Pause on hover */
.marquee--hover-pause:hover .marquee-content {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.scroll-thoughts {
  margin: 0;
  padding: 0;
  /*   height: 300vh; */
  overflow-x: hidden;
  /* background-color: #161616; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.scroll-thoughts p {
  width: 30%;
  margin-bottom: 7lh;
}

.scroll-thoughts > p > div {
  background: linear-gradient(
    to right,
    rgb(37, 37, 37) 50%,
    rgb(255, 255, 255) 50%
  );
  background-size: 200% 100%;
  background-position-x: 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  /*   margin-bottom: -250px; */
  line-height: 200%;
  /*   outline: 6px dotted teal; */
  font-size: 2vw;
}

@media only screen and (max-width: 768px) {
  .scroll-thoughts p {
    width: 60%;
    margin-bottom: 8lh;
  }

  .scroll-thoughts > p > div {
    line-height: 200%;
    /*   outline: 6px dotted teal; */
    font-size: 7vw;
  }

  .marquee .marquee-content {
    animation: scroll 20s linear infinite;
  }
}
