body {
  font-family: "EB Garamond", serif;
}

.full-page {
  height: 100vh;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 30px;
  cursor: default;
  font-size: 46px;
}

.nav-bar .back-arrow {
  cursor: pointer;
}

.nav-bar .back-arrow svg:hover {
  fill: rgb(41, 73, 57);
}

.home {
  font-size: 46px;
  position: relative;
}

.home .content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .home-links {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 60vh;
  justify-content: center;
}

.home .scroll-thoughts {
  margin-top: 120px;
}

.home input {
  width: 500px;
  margin: auto;
}

a:link {
  color: rgb(84, 157, 120);
  text-decoration: none;
}

a:visited {
  color: rgb(41, 73, 57);
  text-decoration: none;
}

a:hover {
  color: rgb(126, 177, 152);
}

.about {
  font-size: 24px;
  height: 80vh;
}

.about img {
  max-height: 200px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  text-align: left;
}

.vibe {
  max-width: 80%;
  max-height: 50%;
}

.vibe:hover {
  cursor: pointer;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.world:hover {
  cursor: pointer;
}

.world {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

img {
  max-width: 300px;
}

/* Media queries */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .home {
    font-size: 36px;
  }
  .home .home-links {
    height: 50vh;
  }

  .home input {
    width: 310px;
  }

  .nav-bar {
    font-size: 36px;
  }

  .about {
    font-size: 18px;
    height: 600px;
  }

  .about img {
    max-height: 150px;
  }

  .content {
    margin: 40px;
    margin-top: 0;
  }

  .world {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}
